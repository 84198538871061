/* eslint-disable no-useless-escape */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _round from 'lodash/round';

const dotSeparator = '.';
const commaSeparator = ',';

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (str1, str2, ignore) {
   return this.replace(
      new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '\\$&'), ignore ? 'gi' : 'g'),
      typeof str2 == 'string' ? str2.replace(/\$/g, '$$$$') : str2
   );
};

class NumberInput extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         value: '',
         formattedValue: '',

         focused: false,
      };
      this.inputRef = null;
   }

   componentDidMount() {
      this.loadInputValue(this.props.value);
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.value !== this.props.value && `${this.props.value}` !== this.state.value) {
         this.loadInputValue(this.props.value);
      }
      if (
         prevProps.decimalSeparator !== this.props.decimalSeparator ||
         prevProps.thousandSeparator !== this.props.thousandSeparator ||
         prevProps.decimalPlaces !== this.props.decimalPlaces
      ) {
         this.loadInputValue(this.props.value, true);
      }
   }

   loadInputValue = (value, useOnChange) => {
      if (!isNaN(Number(value))) {
         let newValue = this.formatDecimal(value, this.props.decimalPlaces);
         let formattedValue = this.parseToFormatValue(newValue);
         this.setState({
            value: `${newValue}`,
            formattedValue: formattedValue,
         });
         if (useOnChange && this.props.onChange) {
            this.props.onChange(`${newValue}`);
         }
      }
   };

   handleChange = async (e) => {
      let oldValue = _cloneDeep(this.state.formattedValue);
      let selectionStart = e.target.selectionStart;

      let formattedValue = this.reFormatValue(e.target.value);
      let value = this.parseToValue(formattedValue);

      if (value !== this.state.value || formattedValue !== this.state.formattedValue) {
         if (this.props.onChange) {
            this.props.onChange(value);
         }
         await this.setState({
            value: value,
            formattedValue: formattedValue,
         });

         let currPos = selectionStart;
         if (this.props.thousandSeparator) {
            let oldSepCount = (oldValue.match(new RegExp(`\\${this.props.thousandSeparator}`, 'g')) || []).length;
            let currSepCount = (formattedValue.match(new RegExp(`\\${this.props.thousandSeparator}`, 'g')) || [])
               .length;
            currPos = currPos + (currSepCount - oldSepCount);
            if (currPos < 0) {
               currPos = 0;
            }
         }
         if (this.inputRef) {
            this.inputRef.setSelectionRange(currPos, currPos);
         }
      }
   };

   reFormatValue = (value = '') => {
      const { decimalSeparator = dotSeparator, thousandSeparator } = this.props;

      let formatValue = thousandSeparator ? `${value}`.replaceAll(thousandSeparator, '') : `${value}`;
      formatValue = formatValue.replace(new RegExp(`[^0-9\\${decimalSeparator}\\${thousandSeparator}\-]`, 'g'), '');

      let matchedValue = formatValue.match(
         new RegExp(`-{0,1}\\d+(?:\\${decimalSeparator}\\d*)?|\\${decimalSeparator}\\d+|\\-`)
      );
      if (matchedValue) {
         formatValue = matchedValue[0];

         if (thousandSeparator) {
            let valueParts = formatValue.split(decimalSeparator);
            valueParts[0] = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
            formatValue = valueParts.join(decimalSeparator);
         }

         return formatValue;
      }
      return '';
   };

   handleBlur = () => {
      let value = this.parseToValue(this.state.formattedValue);
      value = this.formatDecimal(value, this.props.decimalPlaces);

      let formattedValue = this.parseToFormatValue(value);
      if (value !== this.state.value || formattedValue !== this.state.formattedValue) {
         if (this.props.onChange) {
            this.props.onChange(value);
         }
         this.setState({
            value: value,
            formattedValue: formattedValue,
         });
      }
      this.setState({
         focused: false,
      });
      if (this.props.onBlur) {
         this.props.onBlur(value, formattedValue);
      }
   };

   formatDecimal = (value, decimalPlaces) => {
      if (`${decimalPlaces}`.trim() !== '' && `${value}`.trim() !== '') {
         if (isNaN(Number(`${value}`))) {
            return '';
         }
         if (!isNaN(Number(decimalPlaces))) {
            return `${_round(Number(value), Number(decimalPlaces))}`;
         }
      }
      return `${value}`;
   };

   parseToValue = (formattedValue) => {
      if (`${formattedValue}`.trim() === '') {
         return '';
      }
      let value = this.props.thousandSeparator
         ? `${formattedValue}`.replaceAll(this.props.thousandSeparator, '')
         : `${formattedValue}`;
      if (this.props.decimalSeparator !== dotSeparator) {
         value = `${value}`.replace(this.props.decimalSeparator, dotSeparator);
      }
      let matchedValue = value.match(/-{0,1}\d+(?:\.\d*)?|\.\d+/);
      if (matchedValue && matchedValue.length === 1) {
         value = Number(matchedValue[0]);
         if (!isNaN(value)) {
            return `${value}`;
         }
      }
      return this.state.value;
   };

   parseToFormatValue = (value) => {
      const { decimalSeparator = dotSeparator, thousandSeparator } = this.props;
      let formatValue =
         decimalSeparator !== dotSeparator ? `${value}`.replace(dotSeparator, decimalSeparator) : `${value}`;
      if (thousandSeparator) {
         let valueParts = formatValue.split(decimalSeparator);
         valueParts[0] = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
         formatValue = valueParts.join(decimalSeparator);
      }
      return formatValue;
   };

   setInputRef = (r) => {
      this.inputRef = r;
   };

   focusInput = () => {
      if (!this.props.disabled) {
         if (!this.props.readOnly) {
            if (this.inputRef) {
               this.inputRef.focus();
            }
         }
         if (!this.state.focused) {
            this.setState({
               focused: true,
            });
         }
      }
   };

   handleKeyUp = (e) => {
      if (e.key === 'Enter' && this.props.onPressEnter) {
         this.props.onPressEnter(e);
      }
   };

   render() {
      const { formattedValue, focused } = this.state;
      const { name, placeholder, className, readOnly, disabled, suffix, style } = this.props;
      return (
         <div
            className={classNames('input-number-wrapper', className || '', {
               disabled: disabled,
               focused: focused,
            })}
            style={style || {}}
            onFocus={this.focusInput}
            tabIndex={0}
         >
            <input
               ref={this.setInputRef}
               name={name || ''}
               value={formattedValue}
               className="number-formatter"
               placeholder={placeholder || ''}
               readOnly={readOnly}
               disabled={disabled}
               onChange={this.handleChange}
               onBlur={this.handleBlur}
               onKeyUp={this.handleKeyUp}
            />
            {suffix && <span className="number-unit">{suffix}</span>}
         </div>
      );
   }
}
NumberInput.defaultProps = {
   decimalSeparator: dotSeparator,
};

export default NumberInput;
