import React, { memo, useCallback } from 'react';
import { Select as AntdSelect, Spin } from 'antd';
import IconCancel16 from '../Icons/IconCancel16';
import IconArrowDown2 from '../Icons/IconArrowDown2';

const Select = memo(({ onChange, ...props }) => {
   const handleChange = useCallback(
      (v) => {
         if (onChange) {
            onChange(v);
         }
      },
      [onChange]
   );

   return (
      <AntdSelect
         {...props}
         className={`common-input common-input--select ${props.inputClassName || ''}`}
         dropdownClassName="common-input--select-dropdown"
         filterOption={props?.filterOption || false}
         showSearch={props?.showSearch || false}
         suffixIcon={
            props?.loading ? (
               <Spin className="common-spin" size="small" />
            ) : (
               <IconArrowDown2 />
            )
         }
         loading={props?.loading || false}
         notFoundContent={
            props?.loading ? (
               <div className="input-select-fetching">
                  <Spin className="common-spin" size="small" />
               </div>
            ) : (
               props.notFoundContent || (
                  <div className="input-select-not-found">
                     <span>{props.notFoundMessage || 'Does not match any results.'}</span>
                  </div>
               )
            )
         }
         value={props?.value || undefined}
         allowClear={props?.allowClear ?? true}
         showArrow={props?.showArrow ?? true}
         clearIcon={<IconCancel16 />}
         disabled={props?.loading || props?.disabled}
         onChange={handleChange}
      />
   );
});

export default Select;
