import React from 'react';

const IconCalendar = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M5.33301 1.33337V3.33337"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M10.667 1.33337V3.33337"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M2.33301 6.06006H13.6663"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M14 5.66671V11.3334C14 13.3334 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3334 2 11.3334V5.66671C2 3.66671 3 2.33337 5.33333 2.33337H10.6667C13 2.33337 14 3.66671 14 5.66671Z"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M7.99666 9.13338H8.00265"
         stroke="currentColor"
         strokeWidth="1.33333"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M5.52987 9.13338H5.53585"
         stroke="currentColor"
         strokeWidth="1.33333"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M5.52987 11.1334H5.53585"
         stroke="currentColor"
         strokeWidth="1.33333"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export default IconCalendar;
