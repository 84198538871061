import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import Checkbox from './Checkbox';

const CheckboxGroup = memo((props) => {
   const {
      value,
      options,
      className,
      horizontal,
      optionsGridTemplate,
      colorActive,
      onChange,
      readOnly,
      disabled,
   } = props;
   const [currentValue, setCurrentValue] = useState([]);

   useEffect(() => {
      let newValue = [];
      if (value?.length) {
         newValue = value.filter((i) => i);
      }
      setCurrentValue(newValue);
   }, [value]);

   const handleChange = useCallback(
      (e) => {
         if (props.readOnly || props.disabled) return;
         let targetValue = e.target.value;
         let selectedValues = [...currentValue];
         if (e.target.checked) {
            if (!selectedValues.some((v) => v === targetValue)) {
               selectedValues = [...selectedValues, targetValue];
            }
         } else {
            selectedValues = selectedValues.filter((v) => v !== targetValue);
         }
         setCurrentValue(selectedValues);
         if (onChange) {
            const valueString = selectedValues.filter((i) => i);
            onChange(valueString);
         }
      },
      [currentValue, onChange, props.readOnly, props.disabled]
   );

   return (
      <div
         className={classNames(
            className || '',
            'checkbox-group',
            horizontal ? 'horizontal' : '',
            optionsGridTemplate ? 'options-grids' : ''
         )}
         style={optionsGridTemplate ? { gridTemplateColumns: optionsGridTemplate } : {}}
      >
         {options.map((option, idx) => {
            let isChecked = currentValue.some((v) => `${v}`.trim() === option.value);
            return (
               <div className="option-group-wrapper" key={`${option.value}_${idx}`}>
                  <Checkbox
                     label={option.label || ''}
                     value={option.value || ''}
                     colorActive={colorActive}
                     isChecked={isChecked}
                     readOnly={readOnly}
                     disabled={disabled}
                     onChange={handleChange}
                  />
               </div>
            );
         })}
      </div>
   );
});

export default CheckboxGroup;
