import React, { PureComponent } from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';

class Radio extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         hovering: false,
      }
   }

   setHovering = () => {
      if (this.props.colorActive && !this.state.hovering) {
         this.setState({
            hovering: true,
         });
      }
   }

   removeHovering = () => {
      if (this.props.colorActive && this.state.hovering) {
         this.setState({
            hovering: false,
         });
      }
   }

   handleChange = (e) => {
      let targetValue = e.target.value;
      if (e.target.checked) {
         if (this.props.onChange) {
            this.props.onChange(targetValue, e.target.checked);
         }
      }
   }

   handleClick = (e) => {
      if (e.target.value === this.props.value) {
         if (this.props.onChange) {
            this.props.onChange('', false);
         }
      }
   }

   render() {
      const { label, value, isChecked, colorActive, readOnly, disabled, hint } = this.props;
      return (
         <>
            <label className={classNames('radio-wrapper', disabled ? 'disabled' : '')}
               onMouseOver={this.setHovering}
               onMouseLeave={this.removeHovering}
            >
               <span className={`radio ${isChecked ? 'checked' : ''}`}>
                  <input type="radio" value={value} checked={isChecked} onChange={this.handleChange} disabled={readOnly || disabled} onClick={this.handleClick} />
                  <span className="radio-inner"
                     style={(isChecked && colorActive) ? { borderColor: colorActive } :
                        (colorActive && this.state.hovering ? { borderColor: colorActive } : {})}
                  />
               </span>
               {
                  label &&
                  <div className="radio-label">{label}</div>
               }
               {
                  hint &&
                  <div className="radio-hint">{hint}</div>
               }
            </label>

         </>
      );
   }
}

export default Radio;