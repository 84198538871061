export const InputType = {
   TEXT: 'text',
   TEXTAREA: 'textarea',
   PASSWORD: 'password',
   DEBOUNCE_SELECT: 'debounce_select',
   SELECT: 'select',
   NUMBER: 'number',
   DATE: 'date',
   DATE_TIME: 'datetime',
   RADIO: 'radio',
   CHECKBOX: 'checkbox',
   SINGLE_CHECKBOX: 'single_checkbox',
   SWITCH: 'switch',
};
