import { Select, Spin } from 'antd';
import _debounce from 'lodash/debounce';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IconArrowDown2 from '../Icons/IconArrowDown2';
import IconCancel16 from '../Icons/IconCancel16';

const DebounceSelect = memo(({ fetchOptions, debounceTimeout = 450, value, ...props }) => {
   const [fetching, setFetching] = useState(false);
   const [options, setOptions] = useState([]);
   const [currentValue, setCurrentValue] = useState('');
   const fetchRef = useRef(0);
   const abortController = useRef();

   useEffect(() => {
      if (options.length === 0) {
         const loadFirst = async () => {
            await setFetching(true);
            const newOptions = await fetchOptions(props.value);
            setOptions(newOptions);
            setCurrentValue(value || '');
            setFetching(false);
         }
         loadFirst();
      }
      return () => {
         if (abortController.current) {
            abortController.current.abort();
         }
      };
   },
      [fetchOptions, options.length, props?.value, value]
   )

   const debounceFetcher = useMemo(() => {
      const loadOptions = async (value) => {
         if (abortController.current) {
            abortController.current.abort();
         }
         abortController.current = new window.AbortController();
         fetchRef.current += 1;
         const fetchId = fetchRef.current;
         await setFetching(true);
         setOptions([]);
         let searchValue = typeof value === 'string' ? value : '';
         const newOptions = await fetchOptions(searchValue, abortController.current);
         if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
         }

         setOptions(newOptions);
         setFetching(false);
      };

      const debounced = _debounce(loadOptions, debounceTimeout);
      return debounced;
   },
      [fetchOptions, debounceTimeout]
   )

   const onChange = useCallback((v) => {
      setCurrentValue(v);
      if (props.onChange) {
         props.onChange(v);
      }
   },
      [props]
   )

   return (
      <Select
         {...props}
         className={`common-input common-input--select ${props.inputClassName || ''}`}
         dropdownClassName="common-input--select-dropdown"
         filterOption={false}
         showSearch
         onSearch={debounceFetcher}
         suffixIcon={fetching ? <Spin className="common-spin" size="small" /> : <IconArrowDown2 />}
         loading={fetching}
         notFoundContent={fetching ?
            <div className="input-select-fetching">
               <Spin className="common-spin" size="small" />
            </div>
            :
            props.notFoundContent ||
            <div className="input-select-not-found">
               <span>Does not match any results.</span>
            </div>
         }
         value={currentValue || undefined}
         allowClear={true}
         clearIcon={<IconCancel16 />}
         options={options}
         onChange={onChange}
      />
   );
})

export default DebounceSelect;