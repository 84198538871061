import React, { PureComponent } from 'react';

class IconArrowDown2 extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M2 5L8 11L14 5"
               stroke="#828282"
               strokeMiterlimit={10}
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconArrowDown2;