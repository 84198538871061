import React, { PureComponent } from 'react';
import { Input as AntInput } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { InputType } from '../../utils/enums/InputType';
import DebounceSelect from './DebounceSelect';
import Select from './Select';
import RadioGroup from './RadioGroup';
import Checkbox from './Checkbox';
import NumberInput from './NumberInput';
import { Switch } from 'antd';
import DateInput from './DateInput';
import DateTimeInput from './DateTimeInput';
import CheckboxGroup from './CheckboxGroup';

class Input extends PureComponent {
   inputRef = null;

   setInputRef = (r) => {
      this.inputRef = r;
   };

   onCheckboxChange = (e) => {
      if (this.props.onChange) {
         this.props.onChange(e.target.checked);
      }
   };

   onInputChange = (e) => {
      if (this.props.onChange) {
         this.props.onChange(e.target.value);
      }
   };

   onInputNumberChange = (v) => {
      if (this.props.onChange) {
         this.props.onChange(v);
      }
   };

   onRadioChange = (value, checked) => {
      let newValue = value && checked ? value : '';
      if (this.props.onChange) {
         this.props.onChange(newValue);
      }
   };
   onCheckboxGroupChange = (v) => {
      if (this.props.onChange) {
         this.props.onChange(v);
      }
      // let newValue = value && checked ? value : '';
      // if (this.props.onChange) {
      //    this.props.onChange(newValue);
      // }
   };

   renderInput = () => {
      const { type, inputClassName, ...ownProps } = this.props;
      if (type === InputType.TEXTAREA) {
         const { TextArea } = AntInput;
         return (
            <TextArea
               {...ownProps}
               className={`common-input ${inputClassName || ''}`}
               onChange={this.onInputChange}
            />
         );
      } else if (type === InputType.PASSWORD) {
         return (
            <AntInput.Password
               {...ownProps}
               className={`common-input ${inputClassName || ''}`}
               type={type}
               iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
               }
               onChange={this.onInputChange}
            />
         );
      } else if (type === InputType.DEBOUNCE_SELECT) {
         return <DebounceSelect inputClassName={inputClassName} {...ownProps} />;
      } else if (type === InputType.SELECT) {
         return <Select inputClassName={inputClassName} {...ownProps} />;
      } else if (type === InputType.RADIO) {
         return <RadioGroup {...ownProps} onChange={this.onRadioChange} />;
      } else if (type === InputType.CHECKBOX) {
         return <CheckboxGroup {...ownProps} onChange={this.onCheckboxGroupChange} />;
      } else if (type === InputType.SINGLE_CHECKBOX) {
         return (
            <Checkbox
               {...ownProps}
               isChecked={ownProps?.value === true}
               onChange={this.onCheckboxChange}
            />
         );
      } else if (type === InputType.NUMBER) {
         return (
            <NumberInput
               {...ownProps}
               className={`common-input ${inputClassName || ''}`}
               onChange={this.onInputNumberChange}
            />
         );
      } else if (type === InputType.SWITCH) {
         return (
            <>
               <Switch
                  checked={ownProps.value}
                  className={`common-switch ${inputClassName || ''}`}
                  disabled={ownProps.disabled}
                  onChange={this.onInputNumberChange}
               />
               {ownProps?.suffix && (
                  <span className="common-input__suffix">{ownProps.suffix}</span>
               )}
            </>
         );
      } else if (type === InputType.DATE) {
         return (
            <DateInput
               {...ownProps}
               className={`${inputClassName || ''}`}
               onChange={this.onInputNumberChange}
            />
         );
      } else if (type === InputType.DATE_TIME) {
         return (
            <DateTimeInput
               {...ownProps}
               className={`${inputClassName || ''}`}
               onChange={this.onInputNumberChange}
            />
         );
      }
      return (
         <AntInput
            {...ownProps}
            itemRef={this.setInputRef}
            className={`common-input ${inputClassName || ''} ${
               ownProps.disabled ? 'disabled' : ''
            }`}
            type={type}
            onChange={this.onInputChange}
         />
      );
   };

   render() {
      const { className, value, inputTitle } = this.props;
      const showTitle = inputTitle && value ? true : false;
      return (
         <div
            className={`common-input-container ${className || ''} ${
               showTitle ? 'with-title' : ''
            }`}
         >
            <label className="common-input__title">{inputTitle}</label>
            {this.renderInput()}
         </div>
      );
   }
}

export default Input;
