import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import Radio from './Radio';

class RadioGroup extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         value: '',
      }
   }

   componentDidMount() {
      this.setValue(this.props.value);
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
         this.setValue(this.props.value);
      }
   }

   setValue = (value) => {
      this.setState({
         value: value || '',
      });
   }

   checkOptionSelected = (optionValue) => {
      return this.state.value === optionValue;
   }

   handleChange = (value, checked) => {
      this.setState({
         value: value,
      });
      if (this.props.onChange) {
         this.props.onChange(value, checked);
      }
   }

   handleClick = (e) => {
      if (e.target.value === this.state.value) {
         console.log(e.target.value);
         this.setState({
            value: '',
         });
         if (this.props.onChange) {
            this.props.onChange('');
         }
      }
   }


   render() {
      const { className, id, options, readOnly, disabled, horizontal, colorActive, optionsSubContent } = this.props;
      return (
         <div id={id || ''}
            className={classNames(className || '', 'radio-group', horizontal ? 'horizontal' : '')}
         >
            {
               options.map((option, idx) => {
                  let isChecked = this.checkOptionSelected(option.value);
                  let subContent = optionsSubContent ? optionsSubContent.find(o => o.option === option.value) : null;
                  let SubComponent = null;
                  if (subContent) {
                     subContent = subContent.render;
                     SubComponent = subContent.component;
                  }

                  return (
                     <div className="option-group-wrapper" key={`${option.value}_${idx}`}>
                        <Radio
                           label={option.label || ''}
                           value={option.value || ''}
                           hint={option.hint || ''}
                           colorActive={colorActive}
                           isChecked={isChecked}
                           readOnly={readOnly}
                           disabled={disabled}
                           onChange={this.handleChange}
                        />
                        {
                           subContent &&
                           <SubComponent
                              {...subContent.props}
                              childFrames={subContent.children}
                           />
                        }
                     </div>
                  )
               })
            }
         </div>
      );
   }
}

export default RadioGroup;