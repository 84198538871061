import { DatePicker } from 'antd';
import React, { memo, useCallback, useMemo } from 'react';
import moment from 'moment';
import IconCalendar from '@icons/IconCalendar';
import IconClear from '@icons/IconClear';

const displayFormat = 'DD/MM/YYYY HH:mm';
const valueFormat = 'YYYY-MM-DD HH:mm:ss';

const DateTimeInput = memo(({ value, onChange, ...props }) => {
   const handleChange = useCallback(
      (_, dateValue) => {
         if (onChange) {
            onChange(
               dateValue ? moment(dateValue, displayFormat).format(valueFormat) : ''
            );
         }
      },
      [onChange]
   );

   const dateValue = useMemo(() => {
      if (value) {
         return moment(value, valueFormat);
      }
      return '';
   }, [value]);

   const extendedProps = useMemo(() => {
      const p = {};
      if (props.readOnly) {
         p.inputReadOnly = true;
         p.open = false;
         p.allowClear = false;
      }
      return p;
   }, [props.readOnly]);

   return (
      <DatePicker
         {...props}
         className={`common-input ${props.className}`}
         placeholder={props.placeholder || ''}
         dropdownClassName="vgz-picker-dropdown"
         allowClear={true}
         value={dateValue}
         format={displayFormat}
         clearIcon={<IconClear />}
         suffixIcon={<IconCalendar />}
         onChange={handleChange}
         showTime
         {...extendedProps}
      />
   );
});

export default DateTimeInput;
